import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import {
  Text, Heading, Box, SimpleGrid, LinkBox, LinkOverlay,
  useColorModeValue, Button, Wrap, Skeleton
} from "@chakra-ui/react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"

interface PortfolioType {
  data: {
    file: IGatsbyImageData;
    allMdx: {
      edges: EdgeType[];
    }
  }
}
interface EdgeType {
  node: {
    id: any;
    slug: string;
    frontmatter: {
      title: string;
      tags: string[];
      coverimg?: IGatsbyImageData;
    }
  }
}

function Portfolio({ data }: PortfolioType) {
  const portfolioItems = data.allMdx.edges
  const textBg = useColorModeValue('whiteAlpha.700', 'blackAlpha.500')

  const [filter, setFilter] = useState((typeof (window) !== 'undefined') ?
    (
      localStorage.getItem("filter") ?
        String(localStorage.getItem("filter")) : ''
    ) :
    ''
  )
  const [projects, setProjects] = useState([]) as any

  useEffect(() => {
    localStorage.setItem("filter", filter)
  }, [filter])

  useEffect(() => {
    setProjects([]);
    const filtered = !filter ?
      portfolioItems.map(p => ({ ...p, filtered: true })) :
      portfolioItems.map(p => ({ ...p, filtered: p.node.frontmatter.tags.includes(filter) }))
    setProjects(filtered);
  }, [filter])

  return (
    <Layout>
      <SEO title="Portfolio"
        keywords={['portfolio', 'composer', 'musician', 'web-developer']} />
      <Heading fontSize={["6xl", "6xl", "8xl"]}>
        Portfolio
      </Heading>

      <Box>
        <Wrap py={2} align="center">
          <Text>Filter:</Text>

          <Button isActive={filter === 'composition'} onClick={() => setFilter('composition')}>
            Composition
          </Button>
          <Button isActive={filter === 'sound-art'} onClick={() => setFilter('sound-art')}>
            Sound art
            </Button>
            <Button isActive={filter === 'music-tech'} onClick={() => setFilter('music-tech')}>
              Music tech
              </Button>
          <Button isActive={filter === 'code'} onClick={() => setFilter('code')}>
            Code
            </Button>
          <Button isActive={filter === ''} onClick={() => setFilter('')}
          >All
          </Button>
        </Wrap>
      </Box>

      <SimpleGrid columns={[1, 2, 3, 4]} spacing="2">
        {projects.map((node: { node: { frontmatter: { coverimg: FileNode | IGatsbyImageData; title: string }; id: any; slug: string }; filtered: boolean }) => {
          const image = getImage(node.node.frontmatter.coverimg) || getImage(data.file)
          return (
            node.filtered === true ? (
              <Skeleton isLoaded>
              <LinkBox background="green.500" key={node.node.id} display="inline-grid" transition="filter 0.5s" position="relative" filter="grayscale(100%)" _hover={{

                filter: "grayscale(50%)",

              }}>
                <GatsbyImage image={image} alt={node.node.frontmatter.title} height="100%" />


                <LinkOverlay as={Link} to={node.node.slug}>

                  <Heading fontSize={{ base: "3xl", md: "2xl", lg: "3xl" }} wordBreak="break-word" position="absolute" p="1" top="0" bg={textBg}>{node.node.frontmatter.title}</Heading>

                </LinkOverlay>
              </LinkBox>
              </Skeleton>

            ) :
              ''
          )
        })}
      </SimpleGrid>
    </Layout>
  )
}



export const data = graphql`
query MyQuery {
  file(absolutePath: {regex: "/placeholder.jpg/"}) {
    childImageSharp {
      gatsbyImageData
    }
  }
  allMdx(sort: {fields: frontmatter___priority}) {
    edges {
      node {
        id
        frontmatter {
          title
          coverimg {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1)
            }
          }
          tags
        }
        slug
      }
    }
  }
}
`


export default Portfolio